<template>
  <v-container>
    <v-card class="mb-5">
      <v-card-title class="align-start cursor-pointer">
        <div @click="$router.push({name: 'merchant-view', params: { id: router.currentRoute.params.id }})">
          <v-icon>{{ mdiArrowLeftBoldCircleOutline }}</v-icon>
          <div class="d-inline-flex back-to-merchant">Back to merchant</div>
        </div>
      </v-card-title>
      <v-form ref="priceGridForm" @submit.prevent="save()">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="price.visaRdrTier1Cost"
                type="number"
                :label="'Visa RDR Tier 1 Cost'"
                class="visa-rdr-tier1"
                @keyup="validate(PriceConfigServiceType.VISA_RDR)"
                :rules="[validators.required, validators.positiveNumberValidator, validators.lessThanOrEqualValidator(price.visaRdrTier1Cost, price.visaRdrTier2Cost, 'Visa RDR Tier 1 Cost must be less than Visa RDR Tier 2 Cost')]"
                prefix="€"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="price.visaRdrTier2Cost"
                type="number"
                :label="'Visa RDR Tier 2 Cost'"
                class="visa-rdr-tier2"
                @keyup="validate(PriceConfigServiceType.VISA_RDR)"
                :rules="[validators.required, validators.positiveNumberValidator, validators.lessThanOrEqualValidator(price.visaRdrTier2Cost, price.visaRdrTier3Cost, 'Visa RDR Tier 2 Cost must be less than Visa RDR Tier 3 Cost')]"
                prefix="€"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="price.visaRdrTier3Cost"
                type="number"
                :label="'Visa RDR Tier 3 Cost'"
                class="visa-rdr-tier3"
                @keyup="validate(PriceConfigServiceType.VISA_RDR)"
                :rules="[validators.required, validators.positiveNumberValidator]"
                prefix="€"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="price.ethocaCost"
                type="number"
                :label="'Ethoca Cost'"
                class="ethoca-cost"
                :disabled="price.isEnabledEthocaPriceGrid"
                :rules="[validators.required, validators.positiveNumberValidator]"
                prefix="€"
              ></v-text-field>
              <v-checkbox
                v-model="price.isEnabledEthocaPriceGrid"
                label="Enable price grid"
                @change="updateEnableGrid(PriceConfigServiceType.ETHOCA)"
              ></v-checkbox>
              <v-form v-if="price.isEnabledEthocaPriceGrid && price.ethocaPriceGrid.length > 0">
                <v-data-table
                  :headers="headers"
                  :items="price.ethocaPriceGrid"
                  class="elevation-1"
                  :item-class="highlightsTableRow"
                  disable-pagination
                  hide-default-footer
                  disable-sort
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Ethoca Price Grid</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-btn @click="addRow(PriceConfigServiceType.ETHOCA)" color="primary">+</v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.from="{ item }">
                    <v-text-field
                      v-model="item.from"
                      :rules="[validators.required, validators.positiveNumberValidator]"
                      type="number"
                      class="from"
                      @keyup="validate(PriceConfigServiceType.ETHOCA)"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.to="{ item }">
                    <v-text-field
                      v-model="item.to"
                      type="number"
                      class="to"
                      @keyup="validate(PriceConfigServiceType.ETHOCA)"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.cost="{ item }">
                    <v-text-field
                      v-model="item.cost"
                      type="number"
                      class="cost"
                      prefix="€"
                      :rules="[validators.required, validators.positiveNumberValidator]"
                      @keyup="validate(PriceConfigServiceType.ETHOCA)"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.actions="{ index }">
                    <v-icon
                      v-if="index === price.ethocaPriceGrid.length - 1 && index !== 0"
                      small
                      class="mr-2"
                      @click="removeRow(index, PriceConfigServiceType.ETHOCA)"
                    >
                      {{ mdiDelete }}
                    </v-icon>
                  </template>
                </v-data-table>
              </v-form>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="price.visaCdrnCost"
                type="number"
                :label="'Visa CDRN Cost'"
                class="visa-cdrn-cost"
                :disabled="price.isEnabledVisaCdrnPriceGrid"
                :rules="[validators.required, validators.positiveNumberValidator]"
                prefix="€"
              ></v-text-field>
              <v-checkbox
                v-model="price.isEnabledVisaCdrnPriceGrid"
                label="Enable price grid"
                @change="updateEnableGrid(PriceConfigServiceType.VISA_CDRN)"
              ></v-checkbox>
              <v-form v-if="price.isEnabledVisaCdrnPriceGrid && price.visaCdrnPriceGrid.length > 0">
                <v-data-table
                  :headers="headers"
                  :items="price.visaCdrnPriceGrid"
                  class="elevation-1"
                  :item-class="highlightsTableRow"
                  disable-pagination
                  hide-default-footer
                  disable-sort
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Visa CDRN Price Grid</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-btn @click="addRow(PriceConfigServiceType.VISA_CDRN)" color="primary">+</v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.from="{ item }">
                    <v-text-field
                      v-model="item.from"
                      :rules="[validators.required, validators.positiveNumberValidator]"
                      type="number"
                      class="from"
                      @keyup="validate(PriceConfigServiceType.VISA_CDRN)"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.to="{ item }">
                    <v-text-field
                      v-model="item.to"
                      type="number"
                      class="to"
                      @keyup="validate(PriceConfigServiceType.VISA_CDRN)"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.cost="{ item }">
                    <v-text-field
                      v-model="item.cost"
                      type="number"
                      class="cost"
                      prefix="€"
                      :rules="[validators.required, validators.positiveNumberValidator]"
                      @keyup="validate(PriceConfigServiceType.VISA_CDRN)"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.actions="{ index }">
                    <v-icon
                      v-if="index === price.visaCdrnPriceGrid.length - 1 && index !== 0"
                      small
                      class="mr-2"
                      @click="removeRow(index, PriceConfigServiceType.VISA_CDRN)"
                    >
                      {{ mdiDelete }}
                    </v-icon>
                  </template>
                </v-data-table>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="error--text mr-5" v-if="hasError">
            Invalid price grid configuration, please ensure all fields are correctly filled.
          </div>
          <v-btn :disabled="hasError" type="submit" class="save" color="success">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import PriceConfigServiceType from '@/enums/report-service-type.enum';
import Vue from 'vue';
import router from '@/router';
import { mdiArrowLeftBoldCircleOutline, mdiDelete } from '@mdi/js';
import * as validators from '@core/utils/validation';

export default {
  computed: {
    hasError() {
      return this.error.ethocaError || this.error.visaCdrnError || this.error.visaRdrError;
    },
  },
  data() {
    let price = {
      isEnabledVisaCdrnPriceGrid: false,
      isEnabledEthocaPriceGrid: false,
      visaCdrnCost: 0,
      ethocaCost: 0,
      visaRdrTier1Cost: 0,
      visaRdrTier2Cost: 0,
      visaRdrTier3Cost: 0,
      visaCdrnPriceGrid: [],
      ethocaPriceGrid: [],
    };

    return {
      error: {
        visaRdrError: false,
        visaCdrnError: false,
        ethocaError: false,
      },
      priceGridForm: null,
      validators: validators,
      price,
      router,
      PriceConfigServiceType,
      mdiArrowLeftBoldCircleOutline,
      mdiDelete,
      headers: [
        { text: 'From', value: 'from' },
        { text: 'To', value: 'to' },
        { text: 'Cost', value: 'cost' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    updateEnableGrid(type) {
      switch (type) {
        case PriceConfigServiceType.ETHOCA:
          if (this.price.ethocaPriceGrid.length === 0) {
            this.price.ethocaPriceGrid.push({ from: 1, to: null, cost: Number(this.price.ethocaCost), isDefault: true });
          }
          break;
        case PriceConfigServiceType.VISA_CDRN:
          if (this.price.visaCdrnPriceGrid.length === 0) {
            this.price.visaCdrnPriceGrid.push({ from: 1, to: null, cost: Number(this.price.visaCdrnCost), isDefault: true });
          }
          break;
      }
    },
    async getPrices() {
      this.price = await this.$store.dispatch('websitePrice/fetchByWebsiteId', { websiteId: router.currentRoute.params.websiteId });
      this.price.visaCdrnPriceGrid.forEach((priceGrid) => {
        if (priceGrid.cost === this.price.visaCdrnCost) {
          priceGrid.isDefault = true;
        }
      });
      this.price.ethocaPriceGrid.forEach((priceGrid) => {
        if (priceGrid.cost === this.price.ethocaCost) {
          priceGrid.isDefault = true;
        }
      });
    },
    addRow(type) {
      const ethocaLastItem = this.price.ethocaPriceGrid[this.price.ethocaPriceGrid.length - 1];
      const visaCdrnLastItem = this.price.visaCdrnPriceGrid[this.price.visaCdrnPriceGrid.length - 1];
      switch (type) {
        case PriceConfigServiceType.ETHOCA:
          if (ethocaLastItem.to === null) {
            ethocaLastItem.to = Number(ethocaLastItem.from) + 1;
          }
          this.price.ethocaPriceGrid.push({
            from: Number(ethocaLastItem.to) + 1,
            to: null,
            cost: Number(ethocaLastItem.cost) - 1 < 0 ? 0 : Number(ethocaLastItem.cost) - 1,
            hasError: ethocaLastItem.hasError ?? false
          });
          break;
        case PriceConfigServiceType.VISA_CDRN:
          if (visaCdrnLastItem.to === null) {
            visaCdrnLastItem.to = Number(visaCdrnLastItem.from) + 1;
          }
          this.price.visaCdrnPriceGrid.push({
            from: Number(visaCdrnLastItem.to) + 1,
            to: null,
            cost: Number(visaCdrnLastItem.cost) - 1 < 0 ? 0 : Number(visaCdrnLastItem.cost) - 1,
            hasError: visaCdrnLastItem.hasError ?? false
          });
          break;
      }
    },
    removeRow(index, type) {
      const ethocaLastItem = this.price.ethocaPriceGrid[this.price.ethocaPriceGrid.length - 1];
      const visaCdrnLastItem = this.price.visaCdrnPriceGrid[this.price.visaCdrnPriceGrid.length - 1];
      switch (type) {
        case PriceConfigServiceType.ETHOCA:
          if (this.price.ethocaPriceGrid[index].isDefault) {
            this.price.ethocaPriceGrid[0].isDefault = true;
            this.price.ethocaCost = Number(this.price.ethocaPriceGrid[0].cost);
          }
          this.price.ethocaPriceGrid.splice(index, 1);
          ethocaLastItem.to = null;
          break;
        case PriceConfigServiceType.VISA_CDRN:
          if (this.price.visaCdrnPriceGrid[index].isDefault) {
            this.price.visaCdrnPriceGrid[0].isDefault = true;
            this.price.visaCdrnCost = Number(this.price.visaCdrnPriceGrid[0].cost);
          }
          this.price.visaCdrnPriceGrid.splice(index, 1);
          visaCdrnLastItem.to = null;
          break;
      }

      this.validate(type);
    },
    highlightsTableRow(item) {
      if (item.hasError) {
        return 'has-error-price';
      } else if (item.isDefault) {
        return 'default-price';
      }
      return '';
    },
    validate(type) {
      let hasError = false;
      const validatePriceGrid = (priceGrid) => {
        priceGrid.forEach((row, index) => {
          hasError = false;
          if (index === 0 && Number(row.from) !== 1) {
            hasError = true;
          } else if (row.cost !== null && row.cost <= 0) {
            hasError = true;
          } else if ((row.to !== null && row.to !== '') && row.to <= row.from) {
            hasError = true;
          }

          if (index > 0) {
            const prevRow = priceGrid[index - 1];
            if (prevRow.hasError) {
              hasError = true;
            } else if (Number(row.from) !== Number(prevRow.to) + 1) {
              hasError = true;
            } else if (Number(row.cost) !== null && Number(row.cost) >= Number(prevRow.cost)) {
              hasError = true;
            }
          }
          row.hasError = hasError;
        });

        return hasError;
      };
      switch (type) {
        case PriceConfigServiceType.ETHOCA:
          this.error.ethocaError = validatePriceGrid(this.price.ethocaPriceGrid);
          break;
        case PriceConfigServiceType.VISA_CDRN:
          this.error.visaCdrnError = validatePriceGrid(this.price.visaCdrnPriceGrid);
          break;
        case PriceConfigServiceType.VISA_RDR:
          this.error.visaRdrError = !this.$refs.priceGridForm.validate();
          break;
      }

      this.price = { ...this.price };
    },
    save() {
      if (this.hasError) return;

      if (this.price.ethocaPriceGrid.length > 0) {
        this.price.ethocaPriceGrid[this.price.ethocaPriceGrid.length - 1].to = null;
      }

      if (this.price.visaCdrnPriceGrid.length > 0) {
        this.price.visaCdrnPriceGrid[this.price.visaCdrnPriceGrid.length - 1].to = null;
      }

      Vue.$confirm({
        message: 'Are you sure you want to save price config?',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (!confirm) {
            return;
          }
          this.$store.dispatch('websitePrice/update', { websiteId: router.currentRoute.params.websiteId, price: this.price }).then(() => {
            Vue.notify({
              type: 'success',
              text: 'Price config saved successfully.',
            });
          }).catch(() => {
            Vue.notify({
              type: 'error',
              text: 'Failed to save price config.',
            });
          });
        },
      });
    },
  },
  mounted() {
    this.getPrices();
  },
};
</script>

<style lang="scss">
.v-data-table__wrapper {
  margin-bottom: 16px;

  .default-price {
    background-color: rgb(66 249 115 / 40%) !important;
  }
  .has-error-price {
    background-color: rgb(249 66 66 / 40%) !important;
  }
}
.error-message {
  color: rgb(249 66 66 / 40%) !important;
}
</style>
<style scoped lang="scss">
.v-input {
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::v-deep input[type=number] {
    -moz-appearance:textfield;
  }
}
</style>
